import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { checkPermission, contructValidationErrorMessage } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form, Label } from "reactstrap";
import { Constants } from '../../app/constants/Constants';
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { GeneralSubmitObject } from "../../app/models/setting";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import EyeIcon from "mdi-react/EyeIcon";
import EyeOffIcon from "mdi-react/EyeOffIcon"; 
import moment from "moment";

const GeneralSettingsDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { settingsStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, setLoading, setErrorMessage, setSuccessMessage } = commonStore;
  const { generalSettings, getGeneralSettings, updateGeneralSettings, setGeneralSettingsDetail } = settingsStore;
  const displayTitle = `${intl.formatMessage({ id: "GeneralSettings" })}`;
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const [blnViewPassword, setBlnViewPassword] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: generalSettings || {
      id: "",
      domainUrl: "",
      refundProcessingFeeRate: 0,
      isDefaultRefundPolicy: false,
      staffCostAccountNo: "",
      depreciationAccountNo: "",
      commissionAccountNo: "",
      consumableAccountNo: "",
      isInventoryModuleOn: false,
      defaultNumberofTopSelling: 0,
      isTransferCreditEnabled: false,
      timeZoneOffset: 0,
      defaultPassword: "",
      customerLoyaltyThresholdAmount: 0,
      maxPrepaidPackageInSalesOrder: 0,
      acceptableDecimalThreshold: 0,
      paymentAdjustmentAutoApprovalThresholdInHour: 0,
      productExpiredThresholdInDays: 0,
      defaultEffectiveDaysToRequestPaymentAdjustment: 0,
      customerRejoinThresholdInDays : 0,
      salesOrderAutoApprovalThresholdInHour: 0,
      defaultEffectiveDaysToVoidSalesOrder: 0,
      redemptionAutoApprovalThresholdInHour: 0,
      defaultEffectiveDaysToVoidRedemption: 0,
      isAllowCrossMonthSales: false,
      isShowAppolous: false,
      isAllowPartialPayment: false,
      partialPaymentDisableDate: "",
      isEnablePackageUpgradeDiscountLimit: false,
      isAllowBackDateAppointment: false,
      isAllowCrossDayVoidSalesOrder: false,
      isAllowCrossDayVoidRedemption: false,
      isAllowCrossDayPaymentAdjustment: false,
      isAllowCreateSalesOrderWithPendingSign: false,
      adminEffectiveDaysToUpdateRedemptionDetail: false,
      isMandatoryAppointmentGender: false,
      isEnableAdminChangePaymentMode: false,
      isEnableCloseCounter: false,
      leadExpiryThresholdInDays: 0,
      leadAppointedByThresholdInDays: 0
    },
    validationSchema: Yup.object({
      domainUrl: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "DomainUrl" }) })),
      refundProcessingFeeRate: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "RefundProcessingFeeRate" }) })),
      staffCostAccountNo: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "StaffCostAccountNo" }) })).nullable(),
      depreciationAccountNo: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "DepreciationAccountNo" }) })).nullable(),
      commissionAccountNo: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "CommissionAccountNo" }) })).nullable(),
      consumableAccountNo: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "ConsumableAccountNo" }) })).nullable(),
      defaultPassword: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "DefaultPassword" }) })).nullable(),
      customerLoyaltyThresholdAmount: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "CustomerLoyaltyThresholdAmount" }) })),
      acceptableDecimalThreshold: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "AcceptableDecimalThreshold" }) })),
      maxPrepaidPackageInSalesOrder: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "MaxPrepaidPackageInSalesOrder" }) })),
      productExpiredThresholdInDays: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "ProductExpiredThresholdInDays" }) })),
      customerRejoinThresholdInDays: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "CustomerRejoinThresholdInDays" }) })),
      paymentAdjustmentAutoApprovalThresholdInHour: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "PaymentAdjustmentAutoApprovalThresholdInHour" }) })),
      defaultEffectiveDaysToRequestPaymentAdjustment: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "DefaultEffectiveDaysToRequestPaymentAdjustment" }) })),
      salesOrderAutoApprovalThresholdInHour: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "SalesOrderAutoApprovalThresholdInHour" }) })),
      defaultEffectiveDaysToVoidSalesOrder: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "DefaultEffectiveDaysToVoidSalesOrder" }) })),
      redemptionAutoApprovalThresholdInHour: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "RedemptionAutoApprovalThresholdInHour" }) })),
      defaultEffectiveDaysToVoidRedemption: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "DefaultEffectiveDaysToVoidRedemption" }) })),
      partialPaymentDisableDate: Yup.string().test(
        "partialPaymentDisableDate",
        intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "PartialPaymentDisableDate" }) }),
        (value: any, context) => {
          if (!context.parent.isAllowPartialPayment && !value) {
            return value;
          }
          return true;
        }
      ).nullable(),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      if (!blnEditAction) {
        if (valuesTemp.partialPaymentDisableDate) {
          valuesTemp.partialPaymentDisableDate = moment(`${valuesTemp.partialPaymentDisableDate}`, Constants.displayDateFormat).format(Constants.defaultDateFormat)
        }
        try {
          let valuesFinal: GeneralSubmitObject = {
            id: valuesTemp.id,
            values: { ...valuesTemp }
          }
          await updateGeneralSettings(valuesFinal);
        } finally {
          validation.setSubmitting(false);
        }
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const blnEditAction = !checkPermission([PermissionConstants.UpdateGeneralSetting])

  useEffect(() => {
    async function fetchGeneralSettingsDetailAPI() {
      setLoading(true);
      await getGeneralSettings();
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageGeneralSetting], true)) {
      return;
    }

    fetchGeneralSettingsDetailAPI();

    return (() => {
      if (id) {
        setGeneralSettingsDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={[]}
        auditTrailId={undefined}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {/* +true = Convert boolean to numbers*/}
              <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "GeneralSettings" }) })}
                  h4Title />
              <Col xl={6}>
                <SingleColumnRowParent blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "DomainUrl" })}
                    name="domainUrl"
                    className="mb-3 mt-4"
                    type="text"
                    disabled={disabledFieldInput || blnEditAction}
                    validationRequired={true}
                    validation={validation} />
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "RefundProcessingFeeRate" })}
                    name="refundProcessingFeeRate"
                    type="number"
                    disabled={disabledFieldInput || blnEditAction}
                    validationRequired={true}
                    validation={validation} />
                </SingleColumnRowParent>
                <Row>
                  <DoubleColumnRowParent blnDoubleTab>
                    <GeneralInput
                      title={intl.formatMessage({ id: "StaffCostAccountNo" })}
                      name="staffCostAccountNo"
                      type="text"
                      disabled={disabledFieldInput || blnEditAction}
                      validationRequired={true}
                      validation={validation} />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent blnDoubleTab>
                    <GeneralInput
                      title={intl.formatMessage({ id: "DepreciationAccountNo" })}
                      name="depreciationAccountNo"
                      type="text"
                      disabled={disabledFieldInput || blnEditAction}
                      validationRequired={true}
                      validation={validation} />
                  </DoubleColumnRowParent>
                </Row>
                <Row>
                  <DoubleColumnRowParent blnDoubleTab>
                    <GeneralInput
                      title={intl.formatMessage({ id: "CommissionAccountNo" })}
                      name="commissionAccountNo"
                      type="text"
                      disabled={disabledFieldInput || blnEditAction}
                      validationRequired={true}
                      validation={validation} />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent blnDoubleTab>
                    <GeneralInput
                      title={intl.formatMessage({ id: "ConsumableAccountNo" })}
                      name="consumableAccountNo"
                      type="text"
                      disabled={disabledFieldInput || blnEditAction}
                      validationRequired={true}
                      validation={validation} />
                  </DoubleColumnRowParent>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col xs={4} md={4} xl={4}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "InventoryModuleOn" })}
                          name="isInventoryModuleOn"
                          type="checkbox"
                          disabled={disabledFieldInput || blnEditAction}
                          validation={validation}
                          childrenUI={
                            <Label className="margin-bottom-0 margin-left-4">
                              Yes
                            </Label>
                          }
                        />
                      </Col>
                      <Col xs={4} md={4} xl={4}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "DefaultRefundPolicy" })}
                          name="isDefaultRefundPolicy"
                          type="checkbox"
                          disabled={disabledFieldInput || blnEditAction}
                          validation={validation}
                          childrenUI={
                            <Label className="margin-bottom-0 margin-left-4">
                              Yes
                            </Label>
                          }
                        />
                      </Col>
                      <Col xs={4} md={4} xl={4}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "EnableTransferCredit" })}
                          name="isTransferCreditEnabled"
                          type="checkbox"
                          disabled={disabledFieldInput || blnEditAction}
                          validation={validation}
                          childrenUI={
                            <Label className="margin-bottom-0 margin-left-4">
                              Yes
                            </Label>
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col >
                    <Row>
                      <Col xs={4} md={4} xl={4}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "AllowCrossMonthSales" })}
                          name="isAllowCrossMonthSales"
                          type="checkbox"
                          disabled={disabledFieldInput || blnEditAction}
                          validation={validation}
                          childrenUI={
                            <Label className="margin-bottom-0 margin-left-4">
                              Yes
                            </Label>
                          }
                        />
                      </Col>
                      <Col xs={4} md={4} xl={4}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "ShowAppolous" })}
                          name="isShowAppolous"
                          type="checkbox"
                          disabled={disabledFieldInput || blnEditAction}
                          validation={validation}
                          childrenUI={
                            <Label className="margin-bottom-0 margin-left-4">
                              Yes
                            </Label>
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <SingleColumnRowParent blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "DefaultNumberofTopSelling" })}
                    name="defaultNumberofTopSelling"
                    type="text"
                    disabled={disabledFieldInput || blnEditAction}
                    validationRequired={true}
                    validation={validation} />
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "TimeZoneOffset" })}
                    name="timeZoneOffset"
                    type="text"
                    disabled={disabledFieldInput || blnEditAction}
                    validationRequired={true}
                    validation={validation} />
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "CustomerLoyaltyThresholdAmount" })}
                    name="customerLoyaltyThresholdAmount"
                    type="number"
                    disabled={disabledFieldInput || blnEditAction}
                    validationRequired={true}
                    validation={validation} />
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "DefaultPassword" })}
                    name="defaultPassword"
                    type={!blnViewPassword ? "password" : "text"}
                    disabled={disabledFieldInput || blnEditAction}
                    validationRequired={true}
                    validation={validation}
                    childrenUI={!disabledFieldInput ? !blnViewPassword ? <EyeIcon className="mt-2 margin-left-8 text-muted" onClick={() => setBlnViewPassword(true)}/> : <EyeOffIcon className="mt-2 margin-left-8 text-muted" onClick={() => setBlnViewPassword(false)}/> : ""} />
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "MaxPrepaidPackageInSalesOrder" })}
                    name="maxPrepaidPackageInSalesOrder"
                    type={"number"}
                    disabled={disabledFieldInput || blnEditAction}
                    validationRequired={true}
                    validation={validation}/>
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "AcceptableDecimalThreshold" })}
                    name="acceptableDecimalThreshold"
                    type={"number"}
                    disabled={disabledFieldInput || blnEditAction}
                    validationRequired={true}
                    validation={validation}/>
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "ProductExpiredThresholdInDays" })}
                    name="productExpiredThresholdInDays"
                    type={"number"}
                    disabled={disabledFieldInput || blnEditAction}
                    validationRequired={true}
                    validation={validation}/>
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "CustomerRejoinThresholdInDays" })}
                    name="customerRejoinThresholdInDays"
                    type={"number"}
                    disabled={disabledFieldInput || blnEditAction}
                    validationRequired={true}
                    validation={validation}/>
                </SingleColumnRowParent>
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "Lead" })}
                  className="mt-4 fw-bold"
                  titleSize="16px"
                />
                <SingleColumnRowParent blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "LeadRecordExpiredThresholdInDays" })}
                    name="leadExpiryThresholdInDays"
                    type={"number"}
                    disabled={disabledFieldInput || blnEditAction}
                    validationRequired={true}
                    validation={validation}/>
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "LeadRecordAppointedByThresholdInDays" })}
                    name="leadAppointedByThresholdInDays"
                    type={"number"}
                    disabled={disabledFieldInput || blnEditAction}
                    validationRequired={true}
                    validation={validation}/>
                </SingleColumnRowParent>
              </Col>
              <Col xl={6}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "SalesOrder" })}
                  className="mt-0 fw-bold"
                  titleSize="16px" />
                <SingleColumnRowParent blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "AllowCrossDayVoidSalesOrder" })}
                    name="isAllowCrossDayVoidSalesOrder"
                    className={"mb-3 mt-4"}
                    type="checkbox"
                    disabled={disabledFieldInput || blnEditAction}
                    validation={validation}
                    childrenUI={
                      <Label className="margin-bottom-0 margin-left-4">
                        Yes
                      </Label>
                    }
                  />
                </SingleColumnRowParent>
                {
                  validation.values.isAllowCrossDayVoidSalesOrder
                  &&
                  <>
                    <SingleColumnRowParent blnDoubleTab>
                      <GeneralInput
                        title={intl.formatMessage({ id: "SalesOrderAutoApprovalThresholdInHour" })}
                        name="salesOrderAutoApprovalThresholdInHour"
                        type={"number"}
                        disabled={disabledFieldInput || blnEditAction}
                        validationRequired={true}
                        validation={validation}/>
                    </SingleColumnRowParent>
                    <SingleColumnRowParent blnDoubleTab>
                      <GeneralInput
                        title={intl.formatMessage({ id: "DefaultEffectiveDaysToVoidSalesOrder" })}
                        name="defaultEffectiveDaysToVoidSalesOrder"
                        type={"number"}
                        disabled={disabledFieldInput || blnEditAction}
                        validationRequired={true}
                        validation={validation}/>
                    </SingleColumnRowParent>
                  </>
                }
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "Redemption" })}
                  className="mt-4 fw-bold"
                  titleSize="16px"
                />
                <SingleColumnRowParent blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "AllowCrossDayVoidRedemption" })}
                    name="isAllowCrossDayVoidRedemption"
                    className={"mb-3"}
                    type="checkbox"
                    disabled={disabledFieldInput || blnEditAction}
                    validation={validation}
                    childrenUI={
                      <Label className="margin-bottom-0 margin-left-4">
                        Yes
                      </Label>
                    }
                  />
                </SingleColumnRowParent>
                {
                  validation.values.isAllowCrossDayVoidRedemption
                  &&
                  <>
                    <SingleColumnRowParent blnDoubleTab>
                      <GeneralInput
                        title={intl.formatMessage({ id: "RedemptionAutoApprovalThresholdInHour" })}
                        name="redemptionAutoApprovalThresholdInHour"
                        type={"number"}
                        disabled={disabledFieldInput || blnEditAction}
                        validationRequired={true}
                        validation={validation}/>
                    </SingleColumnRowParent>
                    <SingleColumnRowParent blnDoubleTab>
                      <GeneralInput
                        title={intl.formatMessage({ id: "DefaultEffectiveDaysToVoidRedemption" })}
                        name="defaultEffectiveDaysToVoidRedemption"
                        type={"number"}
                        disabled={disabledFieldInput || blnEditAction}
                        validationRequired={true}
                        validation={validation}/>
                    </SingleColumnRowParent>
                  </>
                }
                <SingleColumnRowParent blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "AdminEffectiveDaysToUpdateRedemptionDetail" })}
                    name="adminEffectiveDaysToUpdateRedemptionDetail"
                    className={"mb-3"}
                    type="number"
                    disabled={disabledFieldInput || blnEditAction}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "PaymentControl" })}
                  className="mt-4 fw-bold"
                  titleSize="16px"
                />
                <SingleColumnRowParent blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "AllowCrossDayPaymentAdjustment" })}
                    name="isAllowCrossDayPaymentAdjustment"
                    className={"mb-3"}
                    type="checkbox"
                    disabled={disabledFieldInput || blnEditAction}
                    validation={validation}
                    childrenUI={
                      <Label className="margin-bottom-0 margin-left-4">
                        Yes
                      </Label>
                    }
                  />
                </SingleColumnRowParent>
                {
                  validation.values.isAllowCrossDayPaymentAdjustment
                  &&
                  <>
                    <SingleColumnRowParent blnDoubleTab>
                      <GeneralInput
                        title={intl.formatMessage({ id: "PaymentAdjustmentAutoApprovalThresholdInHour" })}
                        name="paymentAdjustmentAutoApprovalThresholdInHour"
                        type={"number"}
                        disabled={disabledFieldInput || blnEditAction}
                        validationRequired={true}
                        validation={validation}/>
                    </SingleColumnRowParent>
                    <SingleColumnRowParent blnDoubleTab>
                      <GeneralInput
                        title={intl.formatMessage({ id: "DefaultEffectiveDaysToRequestPaymentAdjustment" })}
                        name="defaultEffectiveDaysToRequestPaymentAdjustment"
                        type={"number"}
                        disabled={disabledFieldInput || blnEditAction}
                        validationRequired={true}
                        validation={validation}/>
                    </SingleColumnRowParent>
                  </>
                }
                <SingleColumnRowParent blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "EnableAdminChangePaymentMode" })}
                    name="isEnableAdminChangePaymentMode"
                    className={"mb-3"}
                    type="checkbox"
                    disabled={disabledFieldInput || blnEditAction}
                    validation={validation}
                    childrenUI={
                      <Label className="margin-bottom-0 margin-left-4">
                        Yes
                      </Label>
                    }
                  />
                </SingleColumnRowParent>
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "PartialPaymentFullText" })}
                  className="mt-4 fw-bold"
                  titleSize="16px"
                />
                <SingleColumnRowParent blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "AllowPartialPayment" })}
                    name="isAllowPartialPayment"
                    type="checkbox"
                    disabled={disabledFieldInput || blnEditAction}
                    validationRequired={true}
                    validation={validation}
                    childrenUI={
                      <Label className="margin-bottom-0 margin-left-4">
                        Yes
                      </Label>
                    }
                  />
                  {
                    !validation.values.isAllowPartialPayment
                    &&
                    <GeneralInput
                      title={intl.formatMessage({ id: "PartialPaymentDisableDate" })}
                      name="partialPaymentDisableDate"
                      type="date"
                      disabled={disabledFieldInput || blnEditAction}
                      validationRequired={true}
                      validation={validation}
                    />
                  }
                </SingleColumnRowParent>
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "PackageUpgrade" })}
                  className="mt-4 fw-bold"
                  titleSize="16px"
                />
                <SingleColumnRowParent blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "EnablePackageUpgradeDiscountLimit" })}
                    name="isEnablePackageUpgradeDiscountLimit"
                    type="checkbox"
                    disabled={disabledFieldInput || blnEditAction}
                    validation={validation}
                    childrenUI={
                      <Label className="margin-bottom-0 margin-left-4">
                        Yes
                      </Label>
                    }
                  />
                </SingleColumnRowParent>
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "Appointment" })}
                  className="mt-4 fw-bold"
                  titleSize="16px"
                />
                <SingleColumnRowParent blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "EnableAppointmentBackDate" })}
                    name="isAllowBackDateAppointment"
                    type="checkbox"
                    disabled={disabledFieldInput || blnEditAction}
                    validation={validation}
                    childrenUI={
                      <Label className="margin-bottom-0 margin-left-4">
                        Yes
                      </Label>
                    }
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "MandatoryAppointmentGender" })}
                    name="isMandatoryAppointmentGender"
                    type="checkbox"
                    disabled={disabledFieldInput || blnEditAction}
                    validation={validation}
                    childrenUI={
                      <Label className="margin-bottom-0 margin-left-4">
                        Yes
                      </Label>
                    }
                  />
                </SingleColumnRowParent>
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "Closing" })}
                  className="mt-4 fw-bold"
                  titleSize="16px"
                />
                <SingleColumnRowParent blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "EnableCloseCounter" })}
                    name="isEnableCloseCounter"
                    type="checkbox"
                    disabled={disabledFieldInput || blnEditAction}
                    validation={validation}
                    childrenUI={
                      <Label className="margin-bottom-0 margin-left-4">
                        Yes
                      </Label>
                    }
                  />
                </SingleColumnRowParent>
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              className="mt-2"
              successMessage={successMessage}
              viewAction={blnEditAction}
              validation={validation}
              blnMarginTopByInnerWidth={true}
            />
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(GeneralSettingsDetail);
