import React, { useState } from 'react'
import { useIntl } from 'react-intl';
import { Col, Modal, Row } from 'reactstrap';
import { isTablet } from "react-device-detect";
import { DateRangeInterface } from '../../../app/models/common';
import { firstDayOfMonthToCurrentDay } from '../../../app/common/function/function';
import GeneralDateRange from '../../../app/components/form/GeneralDateRange';
import MyButton from '../../../app/components/form/MyButton';
import { useStore } from '../../../app/stores/store';
import { Constants } from '../../../app/constants/Constants';
import { EmployeeListObject } from '../../../app/models/employee';
import GeneralSelectDropdown from '../../../app/components/form/GeneralSelectDropdown';
import { observer } from 'mobx-react-lite';

interface Props {
  blnShow: boolean;
  setModal: Function;
  marcomConsultantList: EmployeeListObject[];
}

export default observer(function IndividualCSDailyAppointmentModal(props: Props) {
  const intl = useIntl();
  const { commonStore, reportMarcomStore } = useStore();
  const { windowSize } = commonStore;
  const { exportDailyMarcomAppointment } = reportMarcomStore;
  const [localLoading, setLocalLoading] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const disabledFieldInput = localLoading;
  const All = `${intl.formatMessage({ id: "All" })}`;
  const [selectedMarcomConsultantId, setSelectedMarcomConsultantId] = useState(" ");
  const [selectedMarcomConsultantName, setSelectedMarcomConsultantName] = useState("All");

  const hideModal = () => {
    props.setModal(false);
  }

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  const exportDailyMarcomAppointmentReport = async () => {
    setLocalLoading(true);
    await exportDailyMarcomAppointment({
      dateRange: selectedDateRange, employeeId: selectedMarcomConsultantId
    });
    setLocalLoading(false);
  };

  return (
    <Modal
      isOpen={props.blnShow}
      size='lg'
      style={{ ...isTablet && { width: '100%', maxWidth: '75%' } }}
      toggle={() => {
        if (!localLoading) {
          hideModal();
        }
      }}
    centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "IndividualCSDailyAppointment" })}</h5>
        {
          !disabledFieldInput
          &&
          <button
            type="button"
            onClick={() => {
              hideModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        }
      </div>
      <div className="modal-body">
        <div className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "flex-align-items-center-without-height" : ""}`}>
          <Row className={`flex-1`}>
            <Col xl="6">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "MarcomCS" })}
                name={"marcomConsultantId"}
                options={props.marcomConsultantList}
                labelField="preferredName"
                valueField="id"
                initialLabel={selectedMarcomConsultantName}
                initialValue={selectedMarcomConsultantId}
                setFieldLabel={setSelectedMarcomConsultantName}
                setFieldValue={setSelectedMarcomConsultantId}
                disabled={localLoading}
                validationRequired={true}
              />
            </Col>
            <Col xl="6">
              <GeneralDateRange
                title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({ id: "Date" }) })}
                name={"DateRange"}
                disabled={disabledFieldInput}
                onChangeFunction={onSelectDateRange}
                initialValue={selectedDateRange}
                validationRequired={true}
              />
            </Col>
          </Row>
          <MyButton
            type="button"
            class={`btn btn-success ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-12 margin-left-16" : ""}`}
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            loading={disabledFieldInput}
            disable={disabledFieldInput}
            onClick={() => {
              exportDailyMarcomAppointmentReport()
            }}
          />
        </div>
      </div>
    </Modal>
  )
});