import { useEffect, useState } from "react";
import { Col, Modal, Row } from "reactstrap";
import { useIntl } from "react-intl";
import _ from 'lodash';
import { Constants } from "../../../app/constants/Constants";
import GeneralInput from "../../../app/components/form/GeneralInput";
import { useStore } from "../../../app/stores/store";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import { checkPermission, getBranchId } from "../../../app/common/function/function";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import moment from "moment";
import { observer } from 'mobx-react-lite';
import { CustomerValidationResponseObject } from "../../../app/models/customer";
import CustomerTransferRequestModal from "./CustomerTransferRequestModal";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import PaginationTableWithoutApi from "../../../app/components/table/PaginationTableWithoutApi";
import CustomerAccessRequestModal from "./CustomerAccessRequestModal";

interface Props {
  blnShow: boolean;
  setModal: Function;
  setTransferRequestAcknowledgementModal: Function;
  setTransferRequestResponse: Function;
}

interface SearchValueObject {
  searchValue: string;
}

export default observer(function CustomerValidationModal(props: Props) {
  const intl = useIntl();
  //Use Store
  const { staticReferenceStore, commonStore, customerAccessRequestStore, customerTransferRequestStore, customerStore } = useStore();
  const { successMessage, errorMessage, loading, setErrorMessage, setSuccessMessage, setLoading } = commonStore;
  const { getStaticReferenceWithType } = staticReferenceStore;
  const { customerValidate } = customerStore;
  const { addCustomerAccessRequest } = customerAccessRequestStore;
  const { addCustomerTransferRequest } = customerTransferRequestStore;
  const [localLoading, setLocalLoading] = useState(false);

  const [selectedIdentityType, setSelectedIdentityType] = useState<number | string>("");
  const [selectedIdentityTypeId, setSelectedIdentityTypeId] = useState<number | string | undefined>("");
  const [identityTypeDropdownList, setIdentityTypeDropdownList] = useState<StaticReferenceDataObject[]>([]);
  const [searchValueObj, setSearchValueObj] = useState<SearchValueObject>({searchValue: ""})
  const [resultCustomerValidationList, setResultCustomerValidationList] = useState<CustomerValidationResponseObject[]>([]);
  const [customerAccessRequestModal, setCustomerAccessRequestModal] = useState(false);
  const [customerTransferRequestModal, setCustomerTransferRequestModal] = useState(false);
  const [selectedCustomerForAccess, setSelectedCustomerForAccess] = useState<CustomerValidationResponseObject | undefined>(undefined);
  const [selectedCustomerForTransfer, setSelectedCustomerForTransfer] = useState<CustomerValidationResponseObject | undefined>(undefined);
  
  const blnPermissionCreateCustomerAccessRequest = checkPermission([PermissionConstants.CreateCustomerAccessRequest]);
  const blnPermissionCreateCustomerTransferRequest = checkPermission([PermissionConstants.CreateCustomerTransferRequest]);
  
  const disabledFieldInput = loading || localLoading || Boolean(successMessage) || Boolean(errorMessage);

  useEffect(()=> {
    async function fetchIdentityType (){
      setLoading(true);
      let resultIdentityTypeTemp = await getStaticReferenceWithType(Constants.customerValidateType)
      if (resultIdentityTypeTemp) {
        setIdentityTypeDropdownList(resultIdentityTypeTemp);
      }
      setLoading(false);
    }

    fetchIdentityType();
  },[])

  const CustomerValidationColumns = [
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "CustomerName" }).toUpperCase()
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase()
    },
    {
      dataField: "joinedDate",
      text: intl.formatMessage({ id: "JoinedDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {`${moment(row.joinedDate).format(Constants.displayDateAndTimeFormat)}`}
        </div>
      )
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: {textAlign: 'center'},
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {
            row.branchId === getBranchId()
            ?
            Constants.emptyDataV2
            :
            <>
            {
              blnPermissionCreateCustomerAccessRequest
              &&
              <MyButton
                type="button"
                class="btn btn-primary margin-right-8"
                content={intl.formatMessage({ id: "RequestAccess" }).toUpperCase()}
                onClick={async () => {
                  setSelectedCustomerForAccess(row)
                  setCustomerAccessRequestModal(true)
                }}
              />
            }
            {
              blnPermissionCreateCustomerTransferRequest &&
                <MyButton
                  type="button"
                  class="btn btn-primary"
                  content={intl.formatMessage({ id: "RequestTransfer" }).toUpperCase()}
                  onClick={async () => {
                    setSelectedCustomerForTransfer(row)
                    setCustomerTransferRequestModal(true)
                  }}
                />
            }
            </>
          }
        </div>
      )
    }
  ]

  const clearSearchValueObj = () => {
    setSearchValueObj({searchValue: ""})
  }

  const hideModal = () => {
    clearSearchValueObj();
    props.setModal(false);
  }

  const fetchCustomerValidationWithLoading = async () => {
    if (!selectedIdentityTypeId && selectedIdentityTypeId !== 0) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "IdentityType" }) }));
      return;
    }
    if (!searchValueObj.searchValue) {
      setErrorMessage(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "SearchValue" }) }));
      return;
    }

    setLocalLoading(true);
    let resultCustomerValidate = await customerValidate({ validateType: selectedIdentityTypeId, validateValue: searchValueObj.searchValue, currentBranchId: getBranchId() });
    if (resultCustomerValidate) {
      if (resultCustomerValidate.status === Constants.success) {
        if (resultCustomerValidate.data.length > 0) {
          setErrorMessage(intl.formatMessage({ id: "CustomerValidationFailed"}, { branchName: resultCustomerValidate.data[0].branchId !== getBranchId() ? resultCustomerValidate.data[0].branchName : intl.formatMessage({ id: "YourBranch" }) }))
          setResultCustomerValidationList(resultCustomerValidate.data);
        }
        else {
          setSuccessMessage(intl.formatMessage({ id: "CustomerValidationSuccess" }))
        }
      }
    }
    setLocalLoading(false);
  };

  const clearResulCustomerValidate = () => {
    if (resultCustomerValidationList) {
      setResultCustomerValidationList([]);
    }
  }

  return (
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        if (!disabledFieldInput) {
          hideModal();
        }
      }}
      size='xl'
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "CustomerValidation"})}</h5>
        {
          !disabledFieldInput &&
            <button
            type="button"
            onClick={() => {
              if (!localLoading) {
                hideModal();
              }
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        }

      </div>
      <div className="modal-body">
        <div className="standard-layout">
          {
            loading
            ?
            <Loading />
            :
            <>
              <Row className={`align-items-md-center ${localLoading && "margin-right-32"}`}>
                <Col xl="5">
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "ValidateType" })}
                    name="customerSearchType"
                    options={identityTypeDropdownList}
                    labelField="displayValue"
                    valueField="key"
                    initialLabel={selectedIdentityType}
                    initialValue={selectedIdentityTypeId}
                    setFieldLabel={setSelectedIdentityType}
                    setFieldValue={setSelectedIdentityTypeId}
                    blnValueWithNewSpace={true}
                    disabled={disabledFieldInput}
                    validationRequired={true}
                    onChange={() => {
                      setErrorMessage("");
                      setSuccessMessage("");
                      clearResulCustomerValidate();
                      clearSearchValueObj();
                    }}
                  />
                </Col>
                <Col xl="5">
                  <GeneralInput
                    title={intl.formatMessage({ id: "SearchValue" })}
                    name="searchValue"
                    type="text"
                    disabled={disabledFieldInput}
                    validationRequired={true}
                    blnModal={true}
                    field={searchValueObj}
                    setFieldValue={setSearchValueObj}
                    onChangeFunction={()=> {
                      clearResulCustomerValidate();
                    }}
                  />
                </Col>
                <Col xl="2">
                  <MyButton
                    type="button"
                    class="btn btn-primary margin-top-12"
                    content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                    loading={localLoading || loading}
                    disable={disabledFieldInput}
                    onClick={() => {
                      fetchCustomerValidationWithLoading()
                      clearResulCustomerValidate();
                    }}
                  />
                </Col>
              </Row>
              {
                resultCustomerValidationList.length > 0
                &&
                <>
                  <hr />
                  <p className="font-size-14 mt-3" style={{color: 'grey'}}>
                    {intl.formatMessage({ id: "RequestCustomerAccessNotes" })}
                  </p>
                  <PaginationTableWithoutApi 
                    title={intl.formatMessage({ id: "ExistingCustomer" })}
                    headerClassName={"table-light"}
                    options={resultCustomerValidationList}
                    columns={CustomerValidationColumns}
                    pageSize={Constants.defaultPageSize}
                    keyField={"customerId"}
                    striped={true}/>
                </>
              }
            </>
          }
        </div>
      </div>
      {
        customerAccessRequestModal
        &&
        <CustomerAccessRequestModal
          blnShow={customerAccessRequestModal}
          setModal={setCustomerAccessRequestModal}
          selectedCustomerForAccess={selectedCustomerForAccess}
          setSelectedCustomerForAccess={setSelectedCustomerForAccess}
          addCustomerAccessRequest={addCustomerAccessRequest}/>
      }
      {
        customerTransferRequestModal &&
        <CustomerTransferRequestModal
          blnShow={customerTransferRequestModal}
          setModal={setCustomerTransferRequestModal}
          selectedCustomerForTransfer={selectedCustomerForTransfer}
          setSelectedCustomerForTransfer={setSelectedCustomerForTransfer}
          addCustomerTransferRequest={addCustomerTransferRequest}
          setTransferRequestAcknowledgementModal={props.setTransferRequestAcknowledgementModal}
          setTransferRequestResponse={props.setTransferRequestResponse}
        />
      }
    </Modal>
    
  )
});