import { useEffect, useState } from "react";
import { Col, Modal, Row } from "reactstrap";
import { useIntl } from "react-intl";
import { useStore } from "../../../app/stores/store";
import MyButton from "../../../app/components/form/MyButton";
import { observer } from 'mobx-react-lite';
import { ObjectForDropdown } from "../../../app/models/common";
import { getDays, getMonths, getYears } from "../../../app/common/function/function";
import { EmployeeListObject } from "../../../app/models/employee";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import moment from "moment";
import _ from "lodash";
import { Constants } from "../../../app/constants/Constants";

interface Props {
  blnShow: boolean;
  setModal: Function;
  marcomConsultantList: EmployeeListObject[];
}

export default observer(function CSCommissionReportModal(props: Props) {
  const intl = useIntl();
  //Use Store
  const { reportMarcomStore, commonStore } = useStore();
  const { windowSize } = commonStore; 
  const { exportCSCommission } = reportMarcomStore;
  const [localLoading, setLocalLoading] = useState(false);
  const All = `${intl.formatMessage({ id: "All" })}`;
  const [selectedMarcomConsultantName, setSelectedMarcomConsultantName] = useState(All);
  const [selectedMarcomConsultantId, setSelectedMarcomConsultantId] = useState(" ");  
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const [dayList, setDayList] = useState<ObjectForDropdown[]>([]);
  const [selectedDay, setSelectedDay] = useState<number | string>(moment().format("D"));
  const [refreshDayDropdown, setRefreshDayDropdown] = useState(false);

  const disabledFieldInput = localLoading;

  useEffect(()=> {
    setYearList(getYears());
    setMonthList(getMonths());
    setDayList(getDays(selectedYear, selectedMonthValue)); 
  }, [])
  
  const hideModal = () => {
    props.setModal(false);
  }

  const exportCSCommissionReport = async () => {
    setLocalLoading(true);
    await exportCSCommission({
      year: selectedYear, 
      month: selectedMonthValue, 
      day: selectedDay,
      employeeId: selectedMarcomConsultantId
    });
    setLocalLoading(false);
  };

  const handleDayDropdown = (year, month) => {
    const latestYear = year || selectedYear;
    const latestMonth = month || selectedMonthValue;
    const dayListTemp = getDays(latestYear, latestMonth);
    setRefreshDayDropdown(true);
    setDayList(dayListTemp);
    if (selectedDay) {
      let indexValidDayFound = _.findIndex(dayListTemp, {value: Number(selectedDay)})
      if (indexValidDayFound === -1) {
        setSelectedDay(dayListTemp[dayListTemp.length-1].value);
      }
    }
    setTimeout(()=> {
      setRefreshDayDropdown(false);
    }, 100)
  }

  return (
    <Modal
      isOpen={props.blnShow}
      size='xl'
      toggle={() => {
        if(!localLoading) {
          hideModal();
        }
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "CSCommissionReport"})}</h5>
        {
          !disabledFieldInput
          &&
          <button
            type="button"
            onClick={() => {
              hideModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        }
      </div>
      <div className="modal-body">
        <div className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "flex-align-items-center-without-height" : ""}`}>
          <Row className={`flex-1`}>
            <Col xl="3">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "AppointedBy" })}
                name={"marcomConsultantId"}
                options={props.marcomConsultantList}
                labelField="preferredName"
                valueField="id"
                initialLabel={selectedMarcomConsultantName}
                initialValue={selectedMarcomConsultantId}
                setFieldLabel={setSelectedMarcomConsultantName}
                setFieldValue={setSelectedMarcomConsultantId}
                disabled={localLoading}
                validationRequired={true} /> 
            </Col>
            <Col xl="3">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Years" })}
                name="years"
                options={yearList}
                initialLabel={selectedYear}
                initialValue={selectedYear}
                setFieldValue={setSelectedYear}
                onChange={(valueYear)=> {
                  handleDayDropdown(valueYear, "")
                }}
                disabled={disabledFieldInput}
                validationRequired={true}
              />
            </Col>
            <Col xl="3">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Month" })}
                name="months"
                // styles={{marginLeft: '25px'}}
                options={monthList}
                initialLabel={selectedMonth}
                initialValue={selectedMonthValue}
                setFieldLabel={setSelectedMonth}
                setFieldValue={setSelectedMonthValue}
                onChange={(valueMonth)=> {
                  handleDayDropdown("", valueMonth)
                }}
                disabled={disabledFieldInput}
                validationRequired={true}
              />
            </Col>
            {
              !refreshDayDropdown
              &&
              <Col xl="3">
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Day" })}
                  name="day"
                  options={dayList}
                  initialLabel={selectedDay}
                  initialValue={selectedDay}
                  setFieldValue={setSelectedDay}
                  disabled={disabledFieldInput}
                  validationRequired={true}
                />
              </Col>
            }
          </Row>
          <MyButton
            type="button"
            class={`btn btn-success ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-12 margin-left-16" : ""}`}
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            loading={disabledFieldInput}
            disable={disabledFieldInput}
            onClick={() => {
              exportCSCommissionReport()
            }}
          />
        </div>
      </div>
    </Modal>
  )
});