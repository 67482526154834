import React from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import MyButton from "../form/MyButton";

interface Props {
	title: string;
  subTitle?: string;
  bottomTitle?: string;
  primaryActionTitle?: string;
  cancelActionTitle?: string;
  showPrompt: boolean;
  setShowPrompt: Function;
  onPrimaryClick: () => void;
  onCancelClick?: () => void;
  localLoading?: boolean;
  blnShowCloseButton?: boolean;
  blnHideCancelButton?: boolean;
  blnDisableToggle?: boolean;
  replaceIcon?: Function;
}

export default observer(function ActionPromptModal(props: Props) {
  //Use Store
  const { commonStore } = useStore();
  const { loading, successMessage } = commonStore;

	return (
		<div>
			<Modal 
        isOpen={props.showPrompt} 
        toggle={() => {
          if (!props.localLoading && !loading && !Boolean(successMessage) && !props.blnDisableToggle) {
            props.setShowPrompt(false);
          }
        }} 
        centered={true}>
        {
          !props.localLoading && !loading && !Boolean(successMessage) && props.blnShowCloseButton
          &&
          <button
            type="button"
            onClick={() => { props.setShowPrompt(false)}}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        }
				<ModalBody className="py-3 px-5">
					<Row>
						<Col lg={12}>
							<div className="text-center">
                {props.replaceIcon ? props.replaceIcon() : <AlertCircleOutlineIcon size={"6em"} color={"orange"} className={"margin-bottom-8"}/>}
								<h4 style={{whiteSpace:"pre-wrap"}}>
									{props.title}
								</h4>
                <h4>
                  {props.subTitle}
                </h4>
							</div>
              <div className="text-danger font-size-12 mt-4"><i>{props.bottomTitle}</i></div>
						</Col>
					</Row>
          <Row>
            <Col>
              <div className={`text-center ${props.bottomTitle ? "mt-3" : ""}`}>
                <MyButton
                  type="button"
                  content={props.primaryActionTitle || "Yes"}
                  class="btn btn-success btn-lg me-2"
                  onClick={() => {
                    props.onPrimaryClick();
                    if (props.localLoading === undefined) {
                      props.setShowPrompt(false);
                    }
                  }}
                  loading={props.localLoading || loading}
                  disable={props.localLoading || loading || Boolean(successMessage)}/>
                {
                  !props.blnHideCancelButton
                  &&
                  <MyButton
                    type="button"
                    content={props.cancelActionTitle || "Cancel"}
                    class="btn btn-danger btn-lg me-2"
                    onClick={() => {
                      if (props.onCancelClick) {
                        props.onCancelClick();
                      }
                      props.setShowPrompt(false)
                    }}
                    loading={props.localLoading || loading}
                    disable={props.localLoading || loading || Boolean(successMessage)}/>
                }
              </div>
            </Col>
          </Row>
				</ModalBody>
			</Modal>
		</div>
	);
})
