import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { AddOptionsValueField, changePaymentModeStatusColor, checkPermission, closeCounterStatusColor, contructValidationErrorMessage, displaySummaryDetailsTableCellUI, IncludesLocationPathName, newSpaceBeforeCapitalLetter, returnBlobImage, returnPriceWithCurrency } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form, Label, Table } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { GeneralAttachmentListInterface, burgerMenu } from "../../app/models/common";
import { history } from "../..";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralAttachment from "../../app/components/form/GeneralAttachment";
import { PreviewCloseCounterDetailsObject } from "../../app/models/closeCounter";
import { PaymentMethodListObject } from "../../app/models/paymentMethod";
import MyButton from "../../app/components/form/MyButton";
import PaginationTableWithoutApi from "../../app/components/table/PaginationTableWithoutApi";
import TableActionButton from "../../app/components/table/TableActionButton";
import SettlementDetailsModal from "./Modal/SettlementDetailsModal";
import PreviewCloseCounterModal from "./Modal/PreviewCloseCounterModal";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import moment from "moment";
import { Link } from "react-router-dom";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";

const CloseCounterDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { closeCounterStore, paymentMethodStore, staticReferenceStore, paymentMethodOptionStore, commonStore } = useStore();
  const { getPaymentMethodDropdown } = paymentMethodStore;
  const { cardType, getStaticReferenceWithType } = staticReferenceStore; 
  const { getPaymentMethodOptionDropdown } = paymentMethodOptionStore;
  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { closeCounterDetail, setCloseCounterDetail, getCloseCounterWithId, updateCloseCounter, previewCloseCounter, submitCloseCounter, getPendingCloseCounter } = closeCounterStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const editAction = IncludesLocationPathName("edit");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "CloseCounter" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "CloseCounter" }), urlPath: RoutesList.closeCounter }];
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const [localPaymentMethodList, setLocalPaymentMethodList] = useState<PaymentMethodListObject[]>([]);
  const [selectedSettlementIndex, setSelectedSettlementIndex] = useState<number | undefined>(undefined);
  const [settlementDetailsModal, setSettlementDetailsModal] = useState(false);
  const [previewCloseCounterModal, setPreviewCloseCounterModal] = useState(false);
  const [blnShowPendingSignModal, setBlnShowPendingSignModal] = useState(false);
  const [previewCloseCounterDetails, setPreviewCloseCounterDetails] = useState<PreviewCloseCounterDetailsObject | undefined>(undefined);
  const [triggerDeleteSettlements, setTriggerDeleteSettlement] = useState<number>(-1);
  const blnPermissionUpdateCloseCounter =  checkPermission([PermissionConstants.UpdateCloseCounter]);
  const blnPermissionManageChangePaymentMode = checkPermission([PermissionConstants.ManageChangePaymentMode]);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnDraftStatus = closeCounterDetail?.status === Constants.draft;
  const blnOverridenStatus = closeCounterDetail?.status === Constants.overridden;
  const localEmptyData = " ";
  const tableDetailWidth = "180px";

  const SettlementsColumn = [
    {
      dataField: "paymentMethodName",
      text: intl.formatMessage({ id: "PaymentMethod" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {row.paymentMethodName || Constants.emptyData}
        </>
      )
    },
    {
      dataField: "optionName",
      text: intl.formatMessage({ id: "PaymentMethodOption" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {row.optionName || Constants.emptyData}
        </>
      )
    },
    {
      dataField: "cardType",
      text: intl.formatMessage({ id: "CardType" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {row.cardType || Constants.emptyData}
        </>
      )
    },
    {
      dataField: "amount",
      text: intl.formatMessage({ id: "Amount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.amount)}
        </div>
      )
    },
    {
      dataField: "overriddenAmount",
      text: intl.formatMessage({ id: "OverriddenAmount" }).toUpperCase(),
      hidden: !blnOverridenStatus,
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.overriddenAmount)}
        </div>
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: {textAlign: "center"},
      hidden: viewAction,
      formatter: (cellContent, row, index) => (
        <div className="d-flex gap-3 flex-justfity-content-center">
          <TableActionButton
            onClick={()=> {
              setSelectedSettlementIndex(index+1)
              setSettlementDetailsModal(true)
            }}
            type={'edit'} />
          <TableActionButton
            type={'delete'}
            onClick={()=> { 
              setSelectedSettlementIndex(index)
              setTriggerDeleteSettlement((triggerDeleteSettlement)=> (triggerDeleteSettlement+1))
            }} />
        </div>
      ),
    }
  ];
  
  const CashOutColumn = [
    {
      dataField: "type",
      text: intl.formatMessage({ id: "Type" }).toUpperCase(),
      style: (cellContent, row, index) => ({
        fontWeight: validation.values.cashOutSummaries.length-1 === index ? Constants.boldFontWeight : "",
        backgroundColor: validation.values.cashOutSummaries.length-1 === index ? Constants.tableCellGrey : "",
      }),
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.type)}
        </div>
      )
    },
    {
      dataField: "amount",
      text: intl.formatMessage({ id: "Amount" }).toUpperCase(),
      style: (cellContent, row, index) => ({
        fontWeight: validation.values.cashOutSummaries.length-1 === index ? Constants.boldFontWeight : "",
        backgroundColor: validation.values.cashOutSummaries.length-1 === index ? Constants.tableCellGrey : "",
      }),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.amount)}
        </div>
      )
    }
  ];

  const displayPaymentDetailsUI = (row, index, paymentDetailsFieldName: string) => {
    let aryDateViews : any = [];
    let aryPaymentModeViews : any = [];
    let aryAccountNoOrCreditCardNoViews : any = [];
    let aryAmountViews : any = [];

    if (index > 0) {
      row[paymentDetailsFieldName].map((value, indexOldPaymentDetail) => {
        aryDateViews.push(
          displayTableCellUI(`${paymentDetailsFieldName}_payment_details_date_${indexOldPaymentDetail}`, indexOldPaymentDetail, row.oldPaymentDetails.length, value.transactionDate ? moment(value.transactionDate).format(Constants.displayDateFormat) : "", true)
        )
        aryPaymentModeViews.push(
          displayTableCellUI(`${paymentDetailsFieldName}_payment_details_payment_mode_${indexOldPaymentDetail}`, indexOldPaymentDetail, row.oldPaymentDetails.length, value.paymentMode)
        )
        aryAccountNoOrCreditCardNoViews.push(
          displayTableCellUI(`${paymentDetailsFieldName}_payment_details_account_no__${indexOldPaymentDetail}`, indexOldPaymentDetail, row.oldPaymentDetails.length, value.cardOrAccountNo)
        )
        aryAmountViews.push(
          displayTableCellUI(`${paymentDetailsFieldName}_payment_details_amount__${indexOldPaymentDetail}`, indexOldPaymentDetail, row.oldPaymentDetails.length, value.amount ? returnPriceWithCurrency(value.amount) : "")
        )
      })
    }
    else {
      aryDateViews.push(
        displayTableCellUI(`${paymentDetailsFieldName}_payment_details_transaction_date_`, 0, 1, intl.formatMessage({ id: "TransactionDate" }).toUpperCase(), true)
      )
      aryPaymentModeViews.push(
        displayTableCellUI(`${paymentDetailsFieldName}_payment_details_payment_mode_`, 0, 1, intl.formatMessage({ id: "PaymentMode" }).toUpperCase())
      )
      aryAccountNoOrCreditCardNoViews.push(
        displayTableCellUI(`${paymentDetailsFieldName}_payment_details_account_no__`, 0, 1, intl.formatMessage({ id: "AccountNoOrCreditCardNo" }).toUpperCase())
      )
      aryAmountViews.push(
        displayTableCellUI(`${paymentDetailsFieldName}_payment_details_amount__`, 0, 1, intl.formatMessage({ id: "Amount" }).toUpperCase())
      )
    }
    
    return (
      <div className="flex-direction-row" style={{alignItems: "center"}}>
        {displayTableDetailColumn(aryDateViews, false, true, "", index === 0)}
        {displayTableDetailColumn(aryPaymentModeViews, true, true, "", index === 0)}
        {displayTableDetailColumn(aryAccountNoOrCreditCardNoViews, true, true, "", index === 0)}
        {displayTableDetailColumn(aryAmountViews, true, false, "", index === 0)}
      </div>
    )
  }

  const returnLocalDetailStyle = (index) => {
    return ({
      width: tableDetailWidth, 
      padding: "0px", 
      backgroundColor: index === 0 ? Constants.tableCellGrey : "",
      border: index === 0 ? `1px solid` : "",
      borderColor: index === 0 ?  Constants.tableCellBorderGrey : "",
      fontWeight: index === 0 ? 600 : ""
    })
  }

  const ChangePaymentModeColumns = [
    {
      dataField: "documentNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
      headerStyle: (cellContent, row, index) => ({
        border: "0px solid"
      }),
      style: (cellContent, row, index) => ({ 
        ...returnLocalDetailStyle(index)
      }),
      formatter: (cellContent, row, index) => (
        index === 0 
        ? 
        localEmptyData
        : 
        displayTableDetailColumn(displayTableCellUI(`document_no_${index}`, 0, 1, row.documentNo, true), false, false, blnPermissionManageChangePaymentMode ? `/${RoutesList.changePaymentMode}/view/${row.documentId}` : "")
      ),
    },
    {
      dataField: "oldPaymentDetails",
      text: intl.formatMessage({ id: "1stPaymentDetails" }).toUpperCase(),
      headerStyle: (cellContent, row, index) => ({
        textAlign: 'center',
        border: "0px solid"
      }),
      style: (cellContent, row, index) => ({ 
        ...returnLocalDetailStyle(index)
      }),
      formatter: (cellContent, row, index) => {
        return (displayPaymentDetailsUI(row, index, "oldPaymentDetails"))
      },
    },
    {
      dataField: "newPaymentDetails",
      text: intl.formatMessage({ id: "2ndPaymentDetails" }).toUpperCase(),
      headerStyle: (cellContent, row, index) => ({
        textAlign: 'center',
        border: "0px solid"
      }),
      style: (cellContent, row, index) => ({ 
        ...returnLocalDetailStyle(index)
      }),
      formatter: (cellContent, row, index) => {
        return (displayPaymentDetailsUI(row, index, "newPaymentDetails"))
      },
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: (cellContent, row, index) => ({
        border: "0px solid"
      }),
      style: (cellContent, row, index) => ({ 
        ...returnLocalDetailStyle(index)
      }),
      formatter: (cellContent, row, index) => (
        index === 0 
        ? 
        localEmptyData
        : 
        displayTableDetailColumn(displayTableCellUI(`status_${index}`, 0, 1, 
          <GeneralStatus
            status={row.status}
            statusColorFunction={changePaymentModeStatusColor}/>, true))
      ),
    }
  ];

  const UnknownCollectionColumns = [
    {
      dataField: "salesOrderNo",
      text: intl.formatMessage({ id: "SalesOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => 
        <>
          {
            blnPermissionManageCustomerSalesOrder
            ?
            <Link to={row.salesOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.salesOrderId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4">{row.salesOrderNo || Constants.emptyData}</Label>
            </Link>
            :
            <Label className="margin-bottom-0 margin-left-4">{row.salesOrderNo || Constants.emptyData}</Label>
          }
        </>
    },
    {
      dataField: "isUnknownPayment",
      text: intl.formatMessage({ id: "IsUnknownPayment" }).toUpperCase(),
      headerStyle: {width: "180px", textAlign: "center"},
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isUnknownPayment ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "paymentDate",
      text: intl.formatMessage({ id: "PaymentDate" }).toUpperCase(),
      formatter: (cellContent, row) => {
        return displaySummaryDetailsTableCellUI(row.paymentDetails, "paymentDate")
      },
    },
    {
      dataField: "paymentMethodName",
      text: intl.formatMessage({ id: "PaymentMethod" }).toUpperCase(),
      formatter: (cellContent, row) => {
        return displaySummaryDetailsTableCellUI(row.paymentDetails, "paymentMethodName")
      },
    },
    {
      dataField: "paidAmount",
      text: intl.formatMessage({ id: "Amount" }).toUpperCase(),
      formatter: (cellContent, row) => {
        return displaySummaryDetailsTableCellUI(row.paymentDetails, "paidAmount", false, true)
      },
    },
  ]

  const displayTableDetailColumn = (valueDisplay, blnPadding?: boolean, showBorderRight?: boolean, hyperLink?: string, blnTableCellBorderGrey?: boolean) => {
    return (
      <div
        style={{
          flex: 1,
          paddingLeft: blnPadding ? "0.75rem" : "",
          paddingRight: blnPadding ? "0.75rem" : "",
          borderRight: showBorderRight ? `solid ${blnTableCellBorderGrey ? Constants.tableCellBorderGrey : Constants.placeholderColor}` : "",
          borderRightWidth: showBorderRight ? "1px" : "",
          alignItems: 'center',
          minWidth: "180px"
        }}>
        {
          hyperLink
          ?
          <Link
            to={hyperLink}
            target={Constants.supportBlank}
            className="flex-direction-row">
            {valueDisplay}
          </Link>
          :
          valueDisplay
        }
      </div>
    )
  }


  const displayTableCellUI = (key, index, totalLength, value, blnDisabledMarginLeft?: boolean) => {
    return (
      <div 
        key={key}
        style={{
          marginLeft: !blnDisabledMarginLeft ? "-0.75rem" : "",
          marginRight: "-0.75rem",
          paddingLeft: "16px",
          paddingRight: '16px',
          minHeight: '60px', 
          alignItems: 'center', 
          display: 'flex', 
          borderBottom: index === totalLength-1 ? "" : "dashed #eff2f7",
          borderBottomWidth: "1px",
        }}>
        {value}
      </div>
    )
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: closeCounterDetail || 
    {
      id: "",
      branchId: "",
      branchName: "",
      submissionDate: "",
      status: "",
      isOverridden: false,
      attachmentFileHistoryIds: [],
      attachmentFiles: [],
      closeCounterDetails: [],
      closeCounterSettlements: [],
      cashOutSummaries: [],
      changePaymentModeSummaries: [],
      unknownCollections: [],
      aryAttachments: [],
      totalAmount: 0,
      totalOverriddenAmount: 0,
      totalCashOutAmount: 0
    },
    validationSchema: Yup.object({
      // closeCounterSettlements: Yup.array().min(1, intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Settlements" }) })),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);

      if (valuesTemp.workflowAction === Constants.preview && valuesTemp.aryAttachments.length === 0) {
        validation.setFieldError("aryAttachments", intl.formatMessage({ id: "ValidationUpload" }, { field: intl.formatMessage({ id: "Attachment" })}))
        return;
      }

      let attachmentFilesTemp : any = [];
      let attachmentFileIdsTemp : any = [];
      await Promise.all(valuesTemp.aryAttachments.map(async (valueAttachmentTemp) => {
        if (valueAttachmentTemp.preview !== "") {
          let fileTemp : any = await returnBlobImage(valueAttachmentTemp.preview);
          let fileWithFileName = new File([fileTemp], valueAttachmentTemp.path);
          attachmentFilesTemp.push(fileWithFileName);
        }
        if (valueAttachmentTemp.id) {
          attachmentFileIdsTemp.push(valueAttachmentTemp.id);
        }
      }))
      delete valuesTemp["aryAttachments"];
      valuesTemp.attachmentFiles = attachmentFilesTemp;
      valuesTemp.attachmentFileHistoryIds = attachmentFileIdsTemp;
      valuesTemp.settlements = valuesTemp.closeCounterSettlements;
      delete valuesTemp["closeCounterSettlements"];

      try {
        if (valuesTemp.workflowAction === Constants.save) {
          await updateCloseCounter(valuesTemp);
        }
        else {
          let resultPreviewCloseCounter : any = await previewCloseCounter(valuesTemp);
          if (resultPreviewCloseCounter.status === Constants.success) {
            setPreviewCloseCounterDetails(resultPreviewCloseCounter.data);
            setPreviewCloseCounterModal(true);
          }
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchCloseCounterDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getCloseCounterWithId(id, false),
        getStaticReferenceWithType(Constants.cardType),
        getPaymentMethodDropdown(),
      ]
      
      let resultAPI = await Promise.all(aryAPI);
      if (resultAPI[0]) {
        if (resultAPI[0].attachmentFiles) {
          let aryAttachmentsTemp : GeneralAttachmentListInterface[]  = [];
          resultAPI[0].attachmentFiles.map((valueAttachmentFile)=> {
            aryAttachmentsTemp.push({
              id: valueAttachmentFile.attachmentFileId,
              preview: "",
              path: valueAttachmentFile.attachmentFileName,
              webUrl: valueAttachmentFile.attachmentWebUrl,
            })
          })
          validation.setFieldValue("aryAttachments", aryAttachmentsTemp);
        }
        else {
          validation.setFieldValue("aryAttachments", []);
        }

        if (resultAPI[0].status === Constants.draft && viewAction && blnPermissionUpdateCloseCounter) {
          burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.closeCounter}/edit/${id}`)} })
        }
        if(resultAPI[0].hasPendingSign && editAction) {
          setBlnShowPendingSignModal(true);
        }
      }

      let paymentMethodListTemp = resultAPI[2];
      paymentMethodListTemp = _.filter(paymentMethodListTemp, {isCustomerCredit: false, isActive: true});
      paymentMethodListTemp = _.filter(paymentMethodListTemp, {isTransferCredit: false, isActive:  true});
      setLocalPaymentMethodList(AddOptionsValueField(paymentMethodListTemp, "name", "id"));

      setLoading(false);
    }

    if (addAction) {
      setCloseCounterDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateCloseCounter], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateCloseCounter], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageCloseCounter], true)) {
        return;
      }
    }

    fetchCloseCounterDetailAPI();

    return (() => {
      if (id && !addAction) {
        setCloseCounterDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  useEffect(()=> {
    if (selectedSettlementIndex !== undefined) {
      if (triggerDeleteSettlements > -1 && selectedSettlementIndex > -1) {
        let closeCounterSettlementsTemp = _.cloneDeep(validation.values.closeCounterSettlements);
        closeCounterSettlementsTemp.splice(selectedSettlementIndex, 1);
        validation.setFieldValue("closeCounterSettlements", closeCounterSettlementsTemp);
      }
    }
  }, [triggerDeleteSettlements])

  useEffect(()=> {
    if (successMessage && previewCloseCounterModal) {
      setTimeout(() => {
        setPreviewCloseCounterModal(false);
      }, Constants.dismissSuccessMessage);
    }
  }, [successMessage])

  const displayTableContent = () => {
    let aryUITemp : any = [];
    validation.values.closeCounterDetails.map((valueCloseCounterDetailTemp, indexCloseCounterDetailTemp)=> {
      if (valueCloseCounterDetailTemp.closeCounterTerminalDetails.length > 0) {
        aryUITemp.push (
          <tr key={`payment_details_${indexCloseCounterDetailTemp}`}>
            <td style={{backgroundColor: "#eeeeee"}}>
              <p style={{fontSize: "12px", fontWeight: "bold"}} className="mb-0">
                {valueCloseCounterDetailTemp.paymentName}
              </p>
            </td>
            <td colSpan={2} style={{backgroundColor: "#eeeeee"}}/>
          </tr>
        )
              
        valueCloseCounterDetailTemp.closeCounterTerminalDetails.map((valueTerminalsTemp, indexTerminalsTemp)=> (
          aryUITemp.push (
            <tr key={`payment_details_${indexCloseCounterDetailTemp}_${indexTerminalsTemp}`}>
              <td style={{alignContent: "space-around"}}>
                {valueTerminalsTemp.terminalName}
              </td>
              <td style={{alignContent: "space-around"}}>
                {returnPriceWithCurrency(valueTerminalsTemp.amount)}
              </td>
              {
                blnOverridenStatus
                &&
                <td style={{alignContent: "space-around"}}>
                  {returnPriceWithCurrency(valueTerminalsTemp.overriddenAmount)}
                </td>
              }
            </tr>
          )
        ))
      }
      else {
        aryUITemp.push (
          <tr 
            key={`payment_details_${indexCloseCounterDetailTemp}_0`}
            style={{
              fontWeight: validation.values.closeCounterDetails.length-1 === indexCloseCounterDetailTemp ? Constants.boldFontWeight : "",
              backgroundColor: validation.values.closeCounterDetails.length-1 === indexCloseCounterDetailTemp ? Constants.tableCellGrey : ""
            }}>
            <td style={{alignContent: "space-around"}}>
              {valueCloseCounterDetailTemp.paymentName}
            </td>
            <td style={{alignContent: "space-around"}}>
              {returnPriceWithCurrency(valueCloseCounterDetailTemp.amount)}
            </td>
            {
              blnOverridenStatus
              &&
              <td style={{alignContent: "space-around"}}>
                {returnPriceWithCurrency(valueCloseCounterDetailTemp.overriddenAmount)}
              </td>       
            }   
          </tr>
        )
      }
    }) 

    return aryUITemp;
  }
  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={12}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "CloseCounter" }) })}
                  h4Title />
                <SingleColumnRowParent>
                  <DropDownWithTitle
                    name={"branchId"}
                    className="mb-3 mt-4"
                    title={intl.formatMessage({ id: "Branch"})}
                    specifyReturnFieldName={[{"field": "branchName", "value": "name"},{"field": "branchId", "value": "id"}]}
                    labelField={"name"}
                    valueField={"id"}
                    options={[]}
                    disabled={true}
                    initialLabel={validation.values.branchName}
                    initialValue={validation.values.branchId}
                    validation={validation}
                    validationRequired={true}/>
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <GeneralInput
                    title={intl.formatMessage({ id: "SubmissionDate" })}
                    name="submissionDate"
                    type="date"
                    disabled={true}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <Label>{intl.formatMessage({ id: "Status" })}</Label>
                  <GeneralStatus
                    status={validation.values.status}
                    statusColorFunction={closeCounterStatusColor}
                    blnDisableCenter={true}/>
                </SingleColumnRowParent>
                {
                  !blnDraftStatus
                  &&
                  <>
                    <LineBreakWithTittle
                      title={intl.formatMessage({ id: "Details" })}
                      className="fw-bold"
                      titleSize="16px"
                    />
                    <Table className="table-fixed table-bordered" style={{ width: '100%' }}>
                      <thead className="table-light">
                        <tr>
                          <th style={{width: "33.33%"}}>{intl.formatMessage({ id: "PaymentMethod" }).toUpperCase()}</th>
                          <th style={{width: "33.33%"}}>{intl.formatMessage({ id: "Amount" }).toUpperCase()}</th>
                          {blnOverridenStatus && <th>{intl.formatMessage({ id: "OverriddenAmount" }).toUpperCase()}</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {displayTableContent()}
                      </tbody>
                    </Table>
                  </>
                }
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "Settlements" })}
                  className="fw-bold"
                  titleSize="16px"
                />
                {
                  !viewAction
                  &&
                  <MyButton
                    type="button"
                    class={`btn btn-success`}
                    style={{ padding: "8px", paddingBottom: "4px", paddingTop: "4px"}}
                    content={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Settlement"})})}
                    disable={disabledFieldInput}
                    onClick={()=> {
                      setSelectedSettlementIndex(undefined);
                      setSettlementDetailsModal(true);
                    }}
                  />
                }
                <div className={viewAction ? "margin-top-4" : "margin-top-16"}>
                  <PaginationTableWithoutApi 
                    title={intl.formatMessage({ id: "Settlements" })}
                    headerClassName={"table-light"}
                    options={validation.values.closeCounterSettlements}
                    columns={SettlementsColumn}
                    pageSize={Constants.maxPageSize}
                    blnHideTotal={true}
                    blnDisabledScroll={true}
                    keyField={"randomId"}/>
                </div>
                {
                  !blnDraftStatus
                  &&
                  <>
                    <LineBreakWithTittle
                      title={intl.formatMessage({ id: "CashOutSummaries" })}
                      className="fw-bold margin-top-48"
                      titleSize="16px"
                      blnSkipMarginTop={true}
                    />
                    <div className={viewAction ? "margin-top-4" : "margin-top-16"}>
                      <PaginationTableWithoutApi 
                        title={intl.formatMessage({ id: "CashOutSummaries" })}
                        headerClassName={"table-light"}
                        options={validation.values.cashOutSummaries}
                        columns={CashOutColumn}
                        pageSize={Constants.maxPageSize}
                        blnHideTotal={true}
                        blnDisabledScroll={true}
                        keyField={"randomId"}/>
                    </div>
                    <LineBreakWithTittle
                      title={intl.formatMessage({ id: "ChangePaymentModeSummaries" })}
                      className="fw-bold margin-top-48"
                      titleSize="16px"
                      blnSkipMarginTop={true}
                    />
                    <div className={viewAction ? "margin-top-4" : "margin-top-16"}>
                      <PaginationTableWithoutApi 
                        title={intl.formatMessage({ id: "ChangePaymentModeSummaries" })}
                        headerClassName={"table-light"}
                        options={validation.values.changePaymentModeSummaries}
                        columns={ChangePaymentModeColumns}
                        pageSize={Constants.maxPageSize}
                        blnHideTotal={true}
                        keyField={"randomId"}/>
                    </div>
                    <LineBreakWithTittle
                      title={intl.formatMessage({ id: "UnknownCollection" })}
                      className="fw-bold margin-top-48"
                      titleSize="16px"
                      blnSkipMarginTop={true}
                    />
                    <div className={viewAction ? "margin-top-4" : "margin-top-16"}>
                      <PaginationTableWithoutApi 
                        title={intl.formatMessage({ id: "UnknownCollection" })}
                        headerClassName={"table-light"}
                        options={validation.values.unknownCollections}
                        columns={UnknownCollectionColumns}
                        pageSize={Constants.maxPageSize}
                        blnHideTotal={true}
                        keyField={"salesOrderId"}/>
                    </div>
                  </>
                }
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "Attachments" })}
                  className="mt-4 fw-bold"
                  titleSize="16px"
                />
                <SingleColumnRowParent>
                  <GeneralAttachment
                    title={""}
                    name="aryAttachments"
                    validation={validation}
                    disabled={disabledFieldInput || viewAction}
                    blnMultipleFile={true}/>
                </SingleColumnRowParent>
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
                successMessage={successMessage}
                viewAction={viewAction}
                validation={validation}
                dirtyDisabled={true}
                showExtraLeftButton={true}
                extraLeftButtonTitle={intl.formatMessage({ id: "Save" })}
                extraLeftButtonColor="btn-primary"
                extraLeftButtonFunction={() => {
                  validation.setFieldValue("workflowAction", Constants.save)
                }}
                hidePrimaryButton={false}
                submitButtonTitle={intl.formatMessage({ id: "Preview" })}
                onClickFunction={() => {
                  validation.setFieldValue("workflowAction", Constants.preview)
                }}
                extraLeftDirtyDisabled={true}
              />
          </Form>
        )}
      </DetailViewLayout>
      {
        settlementDetailsModal
        &&
        <SettlementDetailsModal 
          blnShow={settlementDetailsModal}
          setModal={setSettlementDetailsModal}
          cardType={cardType}
          branchPaymentMethod={localPaymentMethodList}
          selectedSettlementDetailsIndex={selectedSettlementIndex}
          setSelectedSettlementDetailsIndex={setSelectedSettlementIndex}
          getPaymentMethodOptionDropdown={getPaymentMethodOptionDropdown}
          validation={validation}/>
      }
      {
        previewCloseCounterModal
        &&
        <PreviewCloseCounterModal 
          id={id}
          blnShow={previewCloseCounterModal}
          setModal={setPreviewCloseCounterModal}
          previewCloseCounterDetails={previewCloseCounterDetails!}
          submitCloseCounter={submitCloseCounter}/>
      }
      {
        blnShowPendingSignModal
        &&
        <ActionPromptModal 
          title={`${intl.formatMessage({ id: "PendingSignAlert" })}`}
          primaryActionTitle={`Close`}
          showPrompt={blnShowPendingSignModal}
          setShowPrompt={setBlnShowPendingSignModal}
          blnHideCancelButton={true}
          blnShowCloseButton={false}
          blnDisableToggle={true}
          onPrimaryClick={()=> history.push(`/${RoutesList.dashboard}`)}
        />
      }
    </div>
  );
};

export default observer(CloseCounterDetail);